import { all, takeEvery, put, call } from 'redux-saga/effects';
import moment from 'moment';
import actions from './actions';


import httpProvider from '../../helpers/httpProvider';
import urlProvider from '../../helpers/urlProvider';


const timeOffset = (-1)*((new Date()).getTimezoneOffset());

export function* getServiceHistoryLog({ filter }) {       
    try {
        yield put({
            type: actions.GET_SERVICE_LOG_REQUEST
        });

        const result = yield call(httpProvider.get, urlProvider.getComponentHistoryLogUrl(), filter);

        yield put({
            type: actions.GET_SERVICE_LOG_SUCCESS,
            historyLog: result.map(m=> { return {...m, dateCommited: moment(m.dateCommited).add(timeOffset, 'minutes') }})
        });                
    } catch { 
        yield put({
            type: actions.GET_SERVICE_LOG_ERROR
        });          
    }    
}

export function* getServiceForFilter() {       
    const result = yield call(httpProvider.get, urlProvider.getComponentsUrl());

    yield put({
        type: actions.GET_SERVICE_FOR_FILTER_SUCCESS,
        services: result
    });                   
}

export default function* rootSaga() {
    yield all([        
        takeEvery(actions.GET_SERVICE_LOG, getServiceHistoryLog),
        takeEvery(actions.GET_SERVICE_FOR_FILTER, getServiceForFilter)
    ]);
}