import request from 'superagent';
import { Observable } from 'rx';
import urlProvider from './urlProvider';

const fromSuperagent = req => {
    return Observable.create(observer => {
        req.end((err, res) => {
            if (err) {
                if (err.status === 401) {                    
                }
                observer.onError(err);
            } else {
                observer.onNext(res.body || res.text);
            }

            observer.onCompleted();
        });
    });
};

const nocache = (agentRequest, mockIE) => {
    agentRequest.set('X-Requested-With', 'XMLHttpRequest');
    agentRequest.set('Expires', '-1');
    agentRequest.set('Cache-Control', 'no-cache,no-store,must-revalidate,max-age=-1,private');

    // check for ie 11 and Edge
    if ((!(window.ActiveXObject) && 'ActiveXObject' in window) || mockIE
        || navigator.userAgent.indexOf('Edge') >= 0) {
        /*eslint-disable*/
        if (agentRequest._query.length > 0) {
            const query = `${agentRequest._query[0]}&nocache=${Date.now().toString()}`;
            agentRequest._query = [query];
        } else {
            agentRequest._query = [Date.now().toString()];
        }
        /* eslint-enable*/
    }

    return agentRequest;
};

export default {
    async get(url, params = {}, headers = {}) {
        const sourceStream = fromSuperagent(request
            .get(urlProvider.getAbsoluteUrl(url))
            .query(params)
            .set(headers)
            .use(nocache)
            .withCredentials());
        return await sourceStream.toPromise();
    },

    async put(url, requestData = {},  params = {}) {
        const sourceStream = fromSuperagent(request('PUT', urlProvider.getAbsoluteUrl(url))
            .query(params)
            .send(requestData)
            .withCredentials());
        return await sourceStream.toPromise();
    },

    async post(url, requestData = {}, headers = {}) {
        const sourceStream = fromSuperagent(request
            .post(urlProvider.getAbsoluteUrl(url))
            .set(headers)
            .send(requestData)
            .withCredentials());
        return await sourceStream.toPromise();
    },

    async delete(url, params = {}, requestData = {}) {
        const sourceStream = fromSuperagent(request('DELETE', urlProvider.getAbsoluteUrl(url))
            .query(params)
            .send(requestData)
            .withCredentials());
        return await sourceStream.toPromise();
    }
};
