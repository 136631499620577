import { all, takeEvery, put, call } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import actions from './actions';

import httpProvider from '../../helpers/httpProvider';
import urlProvider from '../../helpers/urlProvider';
import maintenaceStatus from '../../enums/maintenaceStatus';

const timeOffset = (-1)*((new Date()).getTimezoneOffset());

export function* getStatusData({ withLoading }) {              
    try {
        if(withLoading) {
            yield put({
                type: actions.GET_STATUS_DATA_REQUEST
            });    
        }

        const [components, maintenances] = yield all([
            call(httpProvider.get, urlProvider.getComponentsUrl()),
            call(httpProvider.get, urlProvider.getAllMaintenanceUrl())
        ]);

        const maintenancesTimezones =  maintenances
            .map( m=> { return {...m, startDate: moment(m.startDate).add(timeOffset, 'minutes') }});

        const activeMaintenances = _.filter(maintenancesTimezones, m=> m.status !== maintenaceStatus.scheduled.id);
        const scheduledMaintenances = _.filter(maintenancesTimezones, m=> m.status === maintenaceStatus.scheduled.id);

        yield put({
            type: actions.GET_STATUS_DATA_SUCCESS,
            components,
            activeMaintenances,
            scheduledMaintenances
        });    

    } catch { 
        yield put({
            type: actions.GET_STATUS_DATA_ERROR
        });          
    }    
}

export default function* rootSaga() {
    yield all([        
        takeEvery(actions.GET_STATUS_DATA, getStatusData),
    ]);
}