import actions from './actions';

const initState = { 
    isLoading: false,    
    historyLog: [],
    services: [],
    error: null
};

export default function scheduledMaintenancesReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_SERVICE_LOG_REQUEST:
            return { 
                ...state,
                isLoading: true
            };
        case actions.GET_SERVICE_LOG_SUCCESS:
            return { 
                ...state,                
                historyLog: action.historyLog,
                isLoading: false
            };
        case actions.GET_SCHEDULED_MAINTENANCE_ERROR:
            return { 
                ...state,  
                isLoading: false, 
                historyLog: [],             
                error: 'Error'
            };
        case actions.GET_SERVICE_FOR_FILTER_SUCCESS:
            return { 
                ...state,                
                services: action.services,
            };
        default:
            return state;
    }
}