import actions from './actions';

const initState = { 
    isLoading: false,    
    maintenances: [],
    components: [],
};

export default function homeReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_HOME_DATA_REQUEST:
            return { 
                ...initState,
                isLoading: true
            };
        case actions.GET_HOME_DATA_SUCCESS:
            return { 
                ...state,                
                maintenances: action.maintenances,
                components: action.components,
                isLoading: false
            };
        case actions.GET_SCHEDULED_MAINTENANCE_ERROR:
            return { 
                ...initState,  
                isLoading: false,
            };
        case actions.UPDATE_COMPONENT_STATUS_SUCCESS:
            const updatedComponents = state.components.map(s => 
                s.id === action.model.componentId 
                    ? { ...s, state: action.model.newState }
                    : s);

            return { ...state, components: [...updatedComponents] };
        default:
            return state;
    }
}