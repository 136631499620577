import Auth from './auth/reducer';
import App from './app/reducer';
import Component from './component/reducer';
import Maintenance from './maintenance/reducer';
import Home from './home/reducer';
import Status from './status/reducer';
import ServiceLog from './serviceHistoryLog/reducer';

export default {
    Auth,
    App,
    Component,
    Maintenance,
    Home,
    Status,
    ServiceLog
};
