const actions = {    
    GET_STATUS_DATA: 'GET_STATUS_DATA',
    GET_STATUS_DATA_REQUEST: 'GET_STATUS_DATA_REQUEST',
    GET_STATUS_DATA_SUCCESS: 'GET_STATUS_DATA_SUCCESS',
    GET_STATUS_DATA_ERROR: 'GET_STATUS_DATA_ERROR',

    getStatusData: (withLoading = true) => {        
        return { type: actions.GET_STATUS_DATA, withLoading };
    }
};
export default actions;