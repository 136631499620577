import actions from './actions';

const initState = { 
    isLoading: false,
    components: [],
};

export default function homeReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_COMPONENTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actions.GET_COMPONENTS_SUCCESS:            
            return {
                ...state,
                isLoading: false,
                components: action.components,
            };
        case actions.GET_TENANTS_ERROR:
            return {
                ...state,
                isLoading: false,
                components: []
            };
        default:
            return state;
    }
}