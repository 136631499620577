import actions from './actions';

const initState = { 
    isLoading: false, 
    components: [],   
    activeMaintenances: [],
    scheduledMaintenances: [],
};

export default function homeReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_STATUS_DATA_REQUEST:
            return { 
                ...initState,
                isLoading: true
            };
        case actions.GET_STATUS_DATA_SUCCESS:
            return { 
                ...state,                
                activeMaintenances: action.activeMaintenances,
                scheduledMaintenances: action.scheduledMaintenances,
                components: action.components,
                isLoading: false
            };
        case actions.GET_SCHEDULED_MAINTENANCE_ERROR:
            return { 
                ...initState,  
                isLoading: false,
            };
        default:
            return state;
    }
}