import actions from './actions';

const initState = { 
    isLoading: false,    
    maintenances: [],
    error: null
};

export default function scheduledMaintenancesReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_SCHEDULED_MAINTENANCE_REQUEST:
            return { 
                ...initState,
                isLoading: true
            };
        case actions.GET_SCHEDULED_MAINTENANCE_SUCCESS:
            return { 
                ...state,                
                maintenances: action.maintenances,
                isLoading: false
            };
        case actions.GET_SCHEDULED_MAINTENANCE_ERROR:
            return { 
                ...initState,  
                isLoading: false,              
                error: 'Get inactive users error'
            };
        case actions.DELETE_MAINTENANCE_SUCCESS:
            const newState = state.maintenances.filter(m => m.id !== action.id)
            return { ...state, maintenances: [...newState] };
        default:
            return state;
    }
}