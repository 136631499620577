import { all, takeEvery, put, call } from 'redux-saga/effects';
import moment from 'moment';
import actions from './actions';
import homeActions from '../home/actions';

import httpProvider from '../../helpers/httpProvider';
import urlProvider from '../../helpers/urlProvider';
import mesage from '../../components/common/mesage';

const timeOffset = (-1)*((new Date()).getTimezoneOffset());

export function* getScheduledMaintenance() {              
        try {
            yield put({
                type: actions.GET_SCHEDULED_MAINTENANCE_REQUEST
            });

            const result = yield call(httpProvider.get, urlProvider.getScheduledMaintenanceUrl());

            if(result) {
                yield put({
                    type: actions.GET_SCHEDULED_MAINTENANCE_SUCCESS,
                    maintenances: result.map(m=> { return {...m, startDate: moment(m.startDate).add(timeOffset, 'minutes') }})
                });                
            } else {
                yield put({
                    type: actions.GET_SCHEDULED_MAINTENANCE_ERROR                    
                });
            }
        } catch { 
            yield put({
                type: actions.GET_SCHEDULED_MAINTENANCE_ERROR
            });          
        }    
}

export function* createMaintenance({maintenance}) {              
    try {
        yield call(httpProvider.post, urlProvider.getMaintenanceUrl(), maintenance);
        
        mesage.success('New maintenance has been scheduled');   

        yield put({
            type: actions.GET_SCHEDULED_MAINTENANCE
        });     
    } catch { 
        mesage.error('Failed to shcedule new maintenance');             
    }    
}

export function* editMaintenance({maintenance}) {              
    try {
        yield call(httpProvider.put, urlProvider.getMaintenanceUrl(), maintenance);
        
        mesage.success('Maintenance has been update');   

        yield put({
            type: actions.GET_SCHEDULED_MAINTENANCE
        });     
    } catch { 
        mesage.error('Failed to update maintenance');             
    }    
}

export function* deleteMaintenance({id}) {              
    try {
        yield call(httpProvider.delete, urlProvider.getMaintenanceUrl() + '/' + id);

        yield put({
            type: actions.DELETE_MAINTENANCE_SUCCESS,
            id
        });     
        mesage.success('Scheduled maintenance has been deleted');          
    } catch { 
        mesage.error('Failed to delete maintenance');             
    }    
}

export function* updateMaintenanceStatus({model, isStart}) {           
    try {
        yield call(httpProvider.post, urlProvider.getMaintenanceUpdateStatusUrl(), model);                
        mesage.success('Maintenance status updated successfuly');

        if(isStart) {
            yield put({
                type: actions.GET_SCHEDULED_MAINTENANCE
            });   
        } else {
            yield put({
                type: homeActions.GET_HOME_DATA
            });    
        }
    } catch { 
        mesage.error('Failed to update maintenance status');       
    }    
}

export default function* rootSaga() {
    yield all([        
        takeEvery(actions.GET_SCHEDULED_MAINTENANCE, getScheduledMaintenance),
        takeEvery(actions.DELETE_MAINTENANCE, deleteMaintenance),
        takeEvery(actions.CREATE_MAINTENANCE, createMaintenance),
        takeEvery(actions.EDIT_MAINTENANCE, editMaintenance),
        takeEvery(actions.UPDATE_MAINTENANCE_STATUS, updateMaintenanceStatus)
    ]);
}