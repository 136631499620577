import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import httpProvider from '../../helpers/httpProvider';
import urlProvider from '../../helpers/urlProvider';

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        yield put({ type: actions.LOADING });
        try {
            const user = yield call(httpProvider.get, urlProvider.getUserProfileUrl());
            yield put({
                type: actions.LOGIN_SUCCESS,
                profile: user
            });
        } catch {
            yield put({
                type: actions.LOGIN_ERROR
            });
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(checkAuthorization)
    ]);
}
