import env from '../environment';

class UrlProvider {
    getApiUrl() {
        return env.apiUrl.indexOf('#') === -1 ? env.apiUrl : window.location.origin;
    }
    // gets an absolute url, prepending a link to the portal
    getAbsoluteUrl(relativeUrl) {
        return `${this.getApiUrl()}/api/${relativeUrl}`;
    }

    getLoginUrl() {
        return `${this.getApiUrl()}/api/auth/signin`;
    }

    getLogoutUrl() {
        return `${this.getApiUrl()}/api/auth/signout`;
    }

    getUserProfileUrl() {
        return 'auth/profile';
    }

    getComponentsUrl() {
        return 'component';
    }

    getComponentUpdateStatusUrl() {
        return 'component/changestate';
    }

    getComponentHistoryLogUrl() {
        return 'component/historylog';
    }

    getScheduledMaintenanceUrl() {
        return 'maintenance/scheduled';
    }

    getActiveMaintenanceUrl() {
        return 'maintenance/active';
    }

    getAllMaintenanceUrl() {
        return 'maintenance/all';
    }

    getMaintenanceUrl() {
        return 'maintenance';
    }

    getMaintenanceUpdateStatusUrl() {
        return 'maintenance/changestatus';
    }

}

export default new UrlProvider();
