const options = [    
    {
        key: '',
        label: 'sidebar.home',
        leftIcon: 'ion-ios-home-outline'
    },
    {
        key: 'maintenance',
        label: 'sidebar.maintenance',
        leftIcon: 'ion-settings'
    },
    {
        key: 'servicehistory',
        label: 'sidebar.servicehistory',
        leftIcon: 'ion-ios-list-outline'
    },
    {
        key: '',
        label: 'sidebar.stausPage',
        leftIcon: 'ion-grid',
        withoutAdmin: true
    }
];
export default options;
