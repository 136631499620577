const actions = {
    GET_COMPONENTS: 'GET_COMPONENTS',
    GET_COMPONENTS_REQUEST: 'GET_COMPONENTS_REQUEST',
    GET_COMPONENTS_SUCCESS: 'GET_COMPONENTS_SUCCESS',
    GET_COMPONENTS_ERROR: 'GET_COMPONENTS_ERROR',

    getComponents: () => {        
        return { type: actions.GET_COMPONENTS };
    }
};
export default actions;