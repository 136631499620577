import { all, put, call, takeLatest } from 'redux-saga/effects';

import actions from './actions';
import httpProvider from '../../helpers/httpProvider';
import urlProvider from '../../helpers/urlProvider';

export function* getComponents() {           
        try {
            yield put({
                type: actions.GET_COMPONENTS_REQUEST
            });            
            const result = yield call(httpProvider.get, urlProvider.getComponentsUrl());
            if(result){                
                yield put({
                    type: actions.GET_COMPONENTS_SUCCESS,
                    components: result
                });  
            } else {
                throw new Error('Failed to store get components');
            }       
        } catch { 
            yield put({
                type: actions.GET_COMPONENTS_ERROR
            });          
        }    
}

export default function* rootSaga() {
    yield all([      
        takeLatest(actions.GET_COMPONENTS, getComponents),
    ]);
}