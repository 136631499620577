import actions from './actions';

const initState = { isLoading: false, isLoggedIn: false, user: null };

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOADING:
            return { ...state, isLoading: true };
        case actions.LOGIN_SUCCESS:
            return {
                isLoading: false,
                isLoggedIn: !!action.profile,
                user: action.profile
            };
        case actions.LOGIN_ERROR:
            return initState;
        case actions.LOGOUT:
            return initState;
        default:
            return state;
    }
}
