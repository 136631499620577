const actions = {    
    GET_SERVICE_LOG: 'GET_SERVICE_LOG',
    GET_SERVICE_LOG_REQUEST: 'GET_SERVICE_LOG_REQUEST',
    GET_SERVICE_LOG_SUCCESS: 'GET_SERVICE_LOG_SUCCESS',
    GET_SERVICE_LOG_ERROR: 'GET_SERVICE_LOG_ERROR',

    GET_SERVICE_FOR_FILTER: 'GET_SERVICE_FOR_FILTER',
    GET_SERVICE_FOR_FILTER_SUCCESS: 'GET_SERVICE_FOR_FILTER_SUCCESS',

    getServiceLog: (filter) => {        
        return { type: actions.GET_SERVICE_LOG, filter };
    },

    getServiceForFilter: () => {        
        return { type: actions.GET_SERVICE_FOR_FILTER };
    }
};

export default actions;