import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import componentSagas from './component/saga';
import maintenanceSagas from './maintenance/saga';
import homeSagas from './home/saga';
import statusSagas from './status/saga';
import serviceLogSagas from './serviceHistoryLog/saga';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        componentSagas(),
        maintenanceSagas(),
        homeSagas(),
        statusSagas(),
        serviceLogSagas(),
    ]);
}
