import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';

import httpProvider from '../../helpers/httpProvider';
import urlProvider from '../../helpers/urlProvider';
import mesage from '../../components/common/mesage';

export function* getHomeData() {              
    try {
        yield put({
            type: actions.GET_HOME_DATA_REQUEST
        });

        const [components, maintenances] = yield all([
            call(httpProvider.get, urlProvider.getComponentsUrl()),
            call(httpProvider.get, urlProvider.getActiveMaintenanceUrl())
        ]);

        yield put({
            type: actions.GET_HOME_DATA_SUCCESS,
            components,
            maintenances
        });    

    } catch { 
        yield put({
            type: actions.GET_HOME_DATA_ERROR
        });          
    }    
}

export function* updateComponentStatus({model}) {           
    try {
        yield call(httpProvider.post, urlProvider.getComponentUpdateStatusUrl(), model);                
        yield put({
            type: actions.UPDATE_COMPONENT_STATUS_SUCCESS,
            model
        });
        mesage.success('Service status updated successfuly');
    } catch { 
        mesage.error('Failed to update service status');       
    }    
}

export default function* rootSaga() {
    yield all([        
        takeEvery(actions.GET_HOME_DATA, getHomeData),
        takeEvery(actions.UPDATE_COMPONENT_STATUS, updateComponentStatus)
    ]);
}