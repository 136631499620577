const actions = {    
    GET_HOME_DATA: 'GET_HOME_DATA',
    GET_HOME_DATA_REQUEST: 'GET_HOME_DATA_REQUEST',
    GET_HOME_DATA_SUCCESS: 'GET_HOME_DATA_SUCCESS',
    GET_HOME_DATA_ERROR: 'GET_HOME_DATA_ERROR',

    UPDATE_COMPONENT_STATUS: 'UPDATE_COMPONENT_STATUS',
    UPDATE_COMPONENT_STATUS_SUCCESS: 'UPDATE_COMPONENT_STATUS_SUCCESS',
    UPDATE_COMPONENT_STATUS_ERROR: 'UPDATE_COMPONENT_STATUS_ERROR',
    
    getHomeData: () => {        
        return { type: actions.GET_HOME_DATA };
    },

    updateComponentStatus: (model) => {     
        return { type: actions.UPDATE_COMPONENT_STATUS, model };
    },
};
export default actions;