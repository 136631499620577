import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, isLoading, ...rest }) => {    
    return (<Route
        {...rest}
        render={props =>
            isLoggedIn || isLoading ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location }
                    }}
                />
            )
        }
    />)
};
const PublicRoutes = ({ history, isLoggedIn, isLoading }) => {    
    return (
        <ConnectedRouter history={history}>
            <div>
                <Route
                    exact
                    path={'/'}
                    component={asyncComponent(() => import('./containers/Status'))}
                />       
                <Route
                    exact
                    path={'/403'}
                    component={asyncComponent(() => import('./containers/Page/404'))}
                />
                <Route
                    exact
                    path={'/500'}
                    component={asyncComponent(() => import('./containers/Page/500'))}
                />
                <Route
                    exact
                    path={'/signin'}
                    component={asyncComponent(() => import('./containers/Page/signin'))}
                />

                <Route
                    path="/auth0loginCallback"
                />                
                <RestrictedRoute
                    path="/admin"
                    component={App}
                    isLoggedIn={isLoggedIn}
                    isLoading={isLoading}
                />
            </div>
        </ConnectedRouter>
    );
};

function mapStateToProps(state) {
    const { isLoggedIn, isLoading } = state.Auth;
    
    return { isLoggedIn, isLoading };
  }

export default connect(mapStateToProps)(PublicRoutes);
