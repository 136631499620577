import React from 'react';
import { message } from 'antd';
import styled from 'styled-components';

const MessageContent = styled.p`
  display: inline-block;
  font-size: 13px;
`;

const sendMessage = (type, msg) => {
    message[type](
        (<MessageContent>
          {msg}
        </MessageContent>),
        3
    );
}

const messageSender = {
    success: (msg) => sendMessage('success', msg),
    error: (msg) => sendMessage('error', msg)
};

export default messageSender;
