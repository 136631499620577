const actions = {    
    GET_SCHEDULED_MAINTENANCE: 'GET_SCHEDULED_MAINTENANCE',
    GET_SCHEDULED_MAINTENANCE_REQUEST: 'GET_SCHEDULED_MAINTENANCE_REQUEST',
    GET_SCHEDULED_MAINTENANCE_SUCCESS: 'GET_SCHEDULED_MAINTENANCE_SUCCESS',
    GET_SCHEDULED_MAINTENANCE_ERROR: 'GET_SCHEDULED_MAINTENANCE_ERROR',

    DELETE_MAINTENANCE: 'DELETE_MAINTENANCE',
    DELETE_MAINTENANCE_SUCCESS: 'DELETE_MAINTENANCE_SUCCESS',
    DELETE_MAINTENANCE_FAILED: 'DELETE_MAINTENANCE_FAILED',

    CREATE_MAINTENANCE: 'CREATE_MAINTENANCE',
    EDIT_MAINTENANCE: 'EDIT_MAINTENANCE',
    UPDATE_MAINTENANCE_STATUS: 'UPDATE_MAINTENANCE_STATUS',
    
    getScheduledMaintenance: () => {        
        return { type: actions.GET_SCHEDULED_MAINTENANCE };
    },

    deleteMaintenance: (id) => {
        return { type: actions.DELETE_MAINTENANCE, id };
    },

    createMaintenance: (maintenance) => {
        return { type: actions.CREATE_MAINTENANCE, maintenance};
    },

    editMaintenance: (maintenance) => {
        return { type: actions.EDIT_MAINTENANCE, maintenance};
    },

    updateMaintenanceStatus: (model, isStart) => {     
        return { type: actions.UPDATE_MAINTENANCE_STATUS, model, isStart };
    }
};
export default actions;