import urlProvider from '../../helpers/urlProvider';

const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOADING: 'LOADING',
    checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
    logout: () => {
        window.location.href = urlProvider.getLogoutUrl();
        return { type: actions.LOGOUT };
    }
};
export default actions;
