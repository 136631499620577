import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import authAction from '../../redux/auth/actions';

const { logout } = authAction;

class TopbarUser extends Component {
    constructor(props) {
        super(props);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hide = this.hide.bind(this);
        this.state = {
            visible: false
        };
    }

    handleVisibleChange() {
        this.setState({ visible: !this.state.visible });
    }

    getUserInitials() {
        if (this.props.user) {
            const { fullName } = this.props.user;
            const initials = fullName.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g) || [];
            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        }
        return '';
    }

    hide() {
        this.setState({ visible: false });
    }

    render() {
        const content = (
            <TopbarDropdownWrapper className="isoUserDropdown">
                <a className="isoDropdownLink" onClick={this.props.logout} href="#">
                    <IntlMessages id="topbar.logout" />
                </a>
            </TopbarDropdownWrapper>
        );

        return (
            <Popover
                content={content}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                arrowPointAtCenter
                placement="bottomLeft"
            >
                <div className="isoImgWrapper">
                    <span title={this.props.user && this.props.user.userFullName}>{this.getUserInitials()}</span>
                </div>
            </Popover>
        );
    }
}
export default connect(
    state => ({
        user: state.Auth.user
    }),
    { logout }
)(TopbarUser);
